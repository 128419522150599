const themeConfig = {
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112],
  colors: {
    // Design system colors.
    brandPrimary: '#193F61',
    brandPrimaryDark: '#0C3254',
    brandSecondary: '#2A679E',
    brandTertiary: '#F05C5C',
    brandTertiaryDark: '#D35252',
    backgroundLight: '#FFFCFA',
    backgroundMedium: '#FFF8F3',
    backgroundDark: '#F7ECE5',
    backgroundBright: '#FFF2E9',
    backgroundWhite: '#FFFFFF',
    backgroundSuccess: '#D9EAD3',
    backgroundBridal: '#FFF1E9',
    backgroundBridalDark: '#FFEBDE',
    backgroundHolidayBlue: '#E4F5FF',
    backgroundHomepageHero: '#CAE9F8',
    backgroundPill: '#EEF7FF',
    backgroundVirtualEventsHero: '#F6F6F5',
    black: '#060606',
    white: '#FFFFFF',
    greyLight: '#F0F0F1',
    greyMedium: '#D0D3D8',
    greyDark: '#5F626C',
    error: '#DD0000',
    success: '#264F13',
    // Theme UI keys.
    text: '#11172B',
    background: '#FFFCFA',
  },
  fonts: {
    body: 'FF DIN Pro, Arial, system-ui, sans-serif',
    heading: 'Blacker Pro, Georgia, serif',
  },
  fontWeights: {
    body: 400,
    book: 500,
    medium: 700,
    heading: 700,
  },
  styles: {
    root: {
      variant: 'text.body1',
      minWidth: '320px',
      WebkitFontSmoothing: 'antialiased',
      '& body.layout-locked': {
        overflowY: 'hidden',
      },
    },
  },
  layout: {
    container: {
      margin: '0 auto',
      maxWidth: '1170px',
      padding: ['0 16px', '0 24px'],
      width: '100%',
    },
    fullWidth: {
      padding: 0,
      overflow: 'hidden',
      width: '100%',
    },
  },
  buttons: {
    primary: {
      appearance: 'none',
      backgroundColor: 'brandTertiary',
      border: '1px solid',
      borderColor: 'brandTertiary',
      borderRadius: '8px',
      color: 'white',
      cursor: 'pointer',
      display: 'inline-block',
      fontFamily: 'body',
      fontSize: '19px',
      fontWeight: 'medium',
      letterSpacing: '1.3px',
      lineHeight: '20px',
      px: '31px',
      pt: '14px',
      pb: '16px',
      textDecoration: 'none',
      textTransform: 'uppercase',
      transition: 'all .15s ease-in-out',
      '&:visited': {
        color: 'white',
      },
      '&:hover': {
        backgroundColor: 'brandTertiaryDark',
        borderColor: 'brandTertiaryDark',
      },
      '&[disabled]': {
        backgroundColor: 'greyDark',
        borderColor: 'greyDark',
      },
      '&:focus:not(:focus-visible)': { outline: 'none' },
    },
    primaryRounded: {
      appearance: 'none',
      backgroundColor: 'brandTertiary',
      border: '1px solid',
      borderColor: 'brandTertiary',
      borderRadius: '20px',
      color: 'white',
      cursor: 'pointer',
      display: 'inline-block',
      fontFamily: 'body',
      fontSize: '18px',
      fontWeight: 'medium',
      letterSpacing: 'normal',
      lineHeight: '24px',
      px: 3,
      pt: '7px',
      pb: '9px',
      textDecoration: 'none',
      transition: 'all .15s ease-in-out',
      '&:visited': {
        color: 'white',
      },
      '&:hover': {
        backgroundColor: 'brandTertiaryDark',
        borderColor: 'brandTertiaryDark',
      },
      '&[disabled]': {
        backgroundColor: 'greyDark',
        borderColor: 'greyDark',
      },
      '&:focus:not(:focus-visible)': { outline: 'none' },
    },
    secondary: {
      appearance: 'none',
      backgroundColor: 'transparent',
      border: '1px solid',
      borderColor: 'brandTertiary',
      borderRadius: '8px',
      color: 'brandTertiary',
      cursor: 'pointer',
      display: 'inline-block',
      fontFamily: 'body',
      fontSize: '19px',
      fontWeight: 'medium',
      letterSpacing: '1.3px',
      lineHeight: '20px',
      px: '31px',
      pt: '14px',
      pb: '16px',
      textDecoration: 'none',
      textTransform: 'uppercase',
      transition: 'all .15s ease-in-out',
      '&:visited': {
        color: 'brandTertiary',
      },
      '&:hover': {
        borderColor: 'brandTertiaryDark',
        color: 'brandTertiaryDark',
      },
      '&[disabled]': {
        borderColor: 'greyMedium',
        color: 'greyMedium',
        cursor: 'default',
      },
      '&:focus:not(:focus-visible)': { outline: 'none' },
    },
    secondaryAlt: {
      appearance: 'none',
      backgroundColor: 'transparent',
      border: '1px solid',
      borderColor: 'white',
      borderRadius: '8px',
      color: 'white',
      cursor: 'pointer',
      display: 'inline-block',
      fontFamily: 'body',
      fontSize: '19px',
      fontWeight: 'medium',
      letterSpacing: '1.3px',
      lineHeight: '20px',
      px: '31px',
      pt: '14px',
      pb: '16px',
      textDecoration: 'none',
      textTransform: 'uppercase',
      transition: 'all .15s ease-in-out',
      '&:visited': {
        color: 'white',
      },
      '&:hover': {
        borderColor: 'white',
        color: 'white',
      },
      '&[disabled]': {
        borderColor: 'greyLight',
        color: 'greyLight',
        cursor: 'default',
      },
      '&:focus:not(:focus-visible)': { outline: 'none' },
    },
    brandRounded: {
      appearance: 'none',
      backgroundColor: 'brandPrimary',
      border: '1px solid',
      borderColor: 'brandPrimary',
      borderRadius: '24px',
      color: 'white',
      cursor: 'pointer',
      display: 'inline-block',
      fontFamily: 'body',
      fontSize: '16px',
      fontWeight: 'medium',
      letterSpacing: '0',
      lineHeight: '22px',
      px: '24px',
      pt: '8px',
      pb: '9px',
      textDecoration: 'none',
      transition: 'all .15s ease-in-out',
      '&:hover': {
        backgroundColor: 'brandPrimaryDark',
        borderColor: 'brandPrimaryDark',
      },
      '&:focus:not(:focus-visible)': { outline: 'none' },
    },
    productAdd: {
      appearance: 'none',
      backgroundColor: 'white',
      border: '2px solid',
      borderColor: 'greyLight',
      borderRadius: '8px',
      color: 'black',
      cursor: 'pointer',
      display: 'inline-block',
      fontFamily: 'body',
      fontSize: '16px',
      fontWeight: 'medium',
      letterSpacing: '1.25px',
      lineHeight: '20px',
      padding: '13px 4px 15px',
      textDecoration: 'none',
      textTransform: 'uppercase',
      transition: 'all .15s ease-in-out',
      '&:hover': {
        borderColor: 'black',
      },
      '&:focus': {
        borderColor: 'black',
      },
      '&:focus:not(:focus-visible)': { outline: 'none' },
    },
    linktree: {
      appearance: 'none',
      backgroundColor: 'white',
      border: '1px solid',
      borderColor: 'black',
      borderRadius: '8px',
      color: 'black',
      cursor: 'pointer',
      display: 'inline-block',
      fontFamily: 'body',
      fontSize: '19px',
      fontWeight: 'medium',
      letterSpacing: '1.3px',
      lineHeight: '20px',
      px: '31px',
      pt: '14px',
      pb: '16px',
      textDecoration: 'none',
      textTransform: 'uppercase',
      transition: 'all .15s ease-in-out',
      '&:hover': {
        backgroundColor: [null, 'black'],
        color: [null, 'white'],
      },
      '&:focus:not(:focus-visible)': { outline: 'none' },
    },
    reset: {
      appearance: 'none',
      backgroundColor: 'transparent',
      border: '0',
      borderRadius: '0',
      color: 'black',
      cursor: 'pointer',
      fontFamily: 'body',
      padding: 0,
      '&:focus:not(:focus-visible)': { outline: 'none' },
    },
  },
  text: {
    title0: {
      fontFamily: 'heading',
      fontSize: ['36px', '46px', '48px', '52px'],
      fontWeight: 'heading',
      letterSpacing: ['-0.4px', null, '-0.5px'],
      lineHeight: ['42px', '52px', '54px', '58px'],
      '@media only screen and (min-width: 1240px)': {
        fontSize: '56px',
        lineHeight: '62px',
      },
    },
    title1: {
      fontFamily: 'heading',
      fontSize: ['36px', '42px', '44px', '48px'],
      fontWeight: 'heading',
      letterSpacing: ['-0.2px', '-0.3px'],
      lineHeight: ['42px', '48px', '50px', '52px'],
    },
    title2: {
      fontFamily: 'heading',
      fontSize: ['28px', '32px', '34px', '36px'],
      fontWeight: 'heading',
      letterSpacing: ['-0.2px', null, '-0.3px'],
      lineHeight: ['36px', '40px', '42px', '46px'],
    },
    title3: {
      fontFamily: 'heading',
      fontSize: ['24px', '26px', null, '30px'],
      fontWeight: 'heading',
      letterSpacing: ['-0.2px', null, '-0.3px'],
      lineHeight: ['32px', '34px', null, '38px'],
    },
    title4: {
      fontFamily: 'heading',
      fontSize: ['20px', null, '22px'],
      fontWeight: 'heading',
      letterSpacing: '0',
      lineHeight: ['28px', '30px'],
    },
    title5: {
      fontFamily: 'body',
      fontSize: '20px',
      fontWeight: 'heading',
      letterSpacing: '0',
      lineHeight: ['24px', null, '26px'],
    },
    title6: {
      fontFamily: 'body',
      fontSize: ['16px', '18px'],
      fontWeight: 'heading',
      letterSpacing: '0',
      lineHeight: ['22px', '24px'],
    },
    subtitle1: {
      fontFamily: 'body',
      fontSize: '16px',
      fontWeight: 'body',
      letterSpacing: '1px',
      lineHeight: '24px',
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontFamily: 'body',
      fontSize: '14px',
      fontWeight: 'body',
      letterSpacing: '0.8px',
      lineHeight: '22px',
      textTransform: 'uppercase',
    },
    body1: {
      fontFamily: 'body',
      fontSize: ['18px', '20px'],
      letterSpacing: '0',
      lineHeight: ['28px', '32px'],
      '& a': {
        color: 'brandPrimary',
      },
    },
    body2: {
      fontFamily: 'body',
      fontSize: ['16px', '18px'],
      letterSpacing: '0',
      lineHeight: ['26px', '28px'],
      '& a': {
        color: 'brandPrimary',
      },
    },
    body3: {
      fontFamily: 'body',
      fontSize: '16px',
      letterSpacing: '0',
      lineHeight: '22px',
      '& a': {
        color: 'brandPrimary',
      },
    },
    bodyArticle: {
      fontFamily: 'body',
      fontSize: ['18px', '19px'],
      letterSpacing: '0',
      lineHeight: ['30px', '32px'],
      '& a': {
        color: 'brandPrimary',
      },
    },
    quote1: {
      fontFamily: 'heading',
      fontSize: ['22px', '28px', '30px', '32px'],
      fontWeight: 'body',
      letterSpacing: ['-0.1px', '-0.2px'],
      lineHeight: ['36px', '44px', '46px', '50px'],
    },
    quote2: {
      fontFamily: 'heading',
      fontSize: ['16px', null, null, '18px'],
      fontWeight: 'book',
      letterSpacing: '0',
      lineHeight: ['28px', null, null, '30px'],
    },
    description: {
      fontSize: '16px',
      letterSpacing: '0',
      lineHeight: '22px',
    },
    finePrint: {
      fontFamily: 'body',
      fontSize: '14px',
      letterSpacing: '0',
      lineHeight: '20px',
    },
    pill: {
      backgroundColor: 'backgroundPill',
      border: '1px solid',
      borderColor: 'brandPrimary',
      borderRadius: '4px',
      color: 'brandPrimary',
      display: 'inline-block',
      fontFamily: 'body',
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '1',
      pt: '5px',
      pb: '7px',
      px: 1,
    },
    callout0: {
      fontFamily: 'body',
      fontSize: ['16px', null, null, '18px'],
      fontWeight: 'heading',
      letterSpacing: '1px',
      lineHeight: ['24px', null, null, '26px'],
      textTransform: 'uppercase',
    },
    callout1: {
      fontFamily: 'body',
      fontSize: ['15px', null, null, '16px'],
      fontWeight: 'heading',
      letterSpacing: '1px',
      lineHeight: ['20px', null, null, '22px'],
      textTransform: 'uppercase',
    },
    callout2: {
      fontFamily: 'body',
      fontSize: '13px',
      letterSpacing: '0.7px',
      lineHeight: '18px',
      textTransform: 'uppercase',
    },
    callout3: {
      fontFamily: 'body',
      fontSize: '15px',
      fontWeight: 'heading',
      lineHeight: '24px',
      textTransform: 'uppercase',
    },
    textLink: {
      color: 'brandPrimary',
      fontSize: '18px',
      fontWeight: 'medium',
      letterSpacing: '0',
      lineHeight: '24px',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    footerLink: {
      color: 'white',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    label: {
      color: 'black',
      fontFamily: 'body',
      fontSize: ['16px', '18px'],
      fontWeight: 'medium',
      lineHeight: ['22px', '24px'],
    },
    navLink: {
      fontFamily: 'body',
      fontSize: '16px',
      fontWeight: 'medium',
      letterSpacing: '1.5px',
    },
    error: {
      color: 'error',
      fontFamily: 'body',
      fontSize: '16px',
      letterSpacing: '0',
      lineHeight: '22px',
    },
  },
}

export default themeConfig
